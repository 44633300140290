<template>
  <div>
    <SubmenuWrapper>
      <SubmenuButton destination="EximaSalesReport">
        <template #header>Sprzedaż nowa</template>
        <template #description>nowe / wznowione wg. daty wystawienia polisy</template>
      </SubmenuButton>
      <SubmenuButton v-if="showAdmin || showAssistant" destination="EximaAssistantEfficiencyReport">
        <template #header>Skuteczność asystentki(-a)</template>
        <template #description>skuteczność spraw wystawione / odrzucone</template>
      </SubmenuButton>
      <SubmenuButton destination="EximaAgentEfficiencyReport">
        <template #header>Skuteczność agent/biuro</template>
        <template #description>skuteczność spraw przekazanych agent &rarr; biuro</template>
      </SubmenuButton>
    </SubmenuWrapper>
    <router-view />
  </div>
</template>

<script>
import SubmenuButton from '../../components/SubmenuButton.vue';

export default {
  name: 'Exima',
  components: {
    SubmenuButton,
  },
  mounted() {
    if (this.$route.name === 'exima') {
      if (this.showAdmin) {
        this.$router.push({ name: 'EximaSalesReport' });
      } else if (this.showAssistant) {
        this.$router.push({ name: 'EximaAssistantEfficiencyReport' });
      } else {
        this.$router.push({ name: 'EximaAgentEfficiencyReport' });
      }
    }
  },
  computed: {
    currentUserType() {
      return this.$store.getters.currentUserType;
    },
    showAdmin() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
    },
    showAssistant() {
      return this.currentUserType === 'asystent' || this.currentUserType === 'pracownik_b';
    },
  },
};
</script>
